import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { BrowserTracing, captureException, init } from '@sentry/react';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { QueryCache, QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

/** Components */
import { LayoutWrapper } from './components/LayoutWrapper';
import { PageWrapper } from './components/PageWrapper/PageWrapper';
import { NoAccessPage } from './pages';
import { SdkSsoInfo } from './pages/SdkInfoPage/SdkInfoPage';
import { SdkSsoLogin } from './pages/SdkSsoLogin/SdkSsoLogin';

/** Theme */
import { darkTheme } from '@/theme';

/** Utils */
import { getEnvironment } from './utils';

/** Api */
import { sdkSsoLogin } from './apis/tass-api/apiUser';
import { sdkLogin } from './apis/admin-api/user';

type RestrictedSDKRouteProps = {
  children: ReactElement<{ successfulSdkLogin: boolean; successfulSdkLoginFallback: boolean }>;
};

const RestrictedSDKRoute: FC<RestrictedSDKRouteProps> = ({ children }) => {
  const [successfulSdkLogin, setSuccessfulSdkLogin] = useState<boolean>(false);
  const [successfulSdkLoginFallback, setSuccessfulSdkLoginFallback] = useState<boolean>(false);
  const [sdkLoginError, setSdkLoginError] = useState<boolean>(false);

  const { mutate: trySdkLogin } = useMutation(sdkLogin, {
    onSuccess: () => {
      setSuccessfulSdkLoginFallback(true);
    },
    onError: () => {
      setSdkLoginError(true);
    },
  });

  const { mutate: trySdkSsoLogin } = useMutation(sdkSsoLogin, {
    onSuccess: () => {
      setSuccessfulSdkLogin(true);
    },
    onError: () => {
      trySdkLogin();
    }
  });

  useEffect(() => {
    trySdkSsoLogin();
  }, []);

  children = React.cloneElement(children, {
    successfulSdkLogin,
    successfulSdkLoginFallback,
  });

  if (sdkLoginError) {
    return <Navigate to={`/no-access`} />;
  } else if (successfulSdkLogin || successfulSdkLoginFallback) {
    return <>{children}</>;
  } else {
    return null;
  }
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageWrapper />,
    children: [
      {
        path: 'authentication/login-callback',
        element: <Navigate to="/" replace />,
      },
      {
        path: '/',
        element: <LayoutWrapper />,
        children: [
          {
            path: '/',
            element: (
              <RestrictedSDKRoute>
                <SdkSsoInfo successfulSdkLogin={false} successfulSdkLoginFallback={false} />
              </RestrictedSDKRoute>
            ),
          },
        ],
      },
      {
        path: 'no-access',
        element: <NoAccessPage />,
      },
    ],
  },
  {
    path: 'sdk/sso-login',
    element: <SdkSsoLogin />,
  },
]);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined) {
        captureException(error);
      }
    },
  }),
});

const App = () => {
  let environment = '';
  let ddEnvironment = '';

  const env = import.meta.env;
  const runningEnvironment = getEnvironment();

  if (runningEnvironment === 'prod') {
    environment = 'production';
    ddEnvironment = 'prod';
  } else if (runningEnvironment === 'staging') {
    environment = 'staging';
    ddEnvironment = 'staging';
  } else if (runningEnvironment === 'dev') {
    environment = 'development';
    ddEnvironment = 'dev';
  }

  if (environment) {
    init({
      dsn: env.VITE_SENTRY_DSN,
      integrations: [new BrowserTracing()],

      // To set a uniform sample rate
      // 0.6 means to send 60% of transactions
      tracesSampleRate: 0.6,
      environment: environment,
      release: env.VITE_APP_VERSION,
    });

    datadogRum.init({
      applicationId: env.VITE_DATADOG_ID,
      clientToken: env.VITE_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      proxy: 'https://drum.treble.tech',
      service: 'treble-management-portal',
      env: ddEnvironment,
      allowedTracingUrls: env.VITE_DATADOG_TRACING_URLS.split(';'),

      // Specify a version number to identify the deployed version of your application in Datadog
      version: env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: environment === 'production' ? 0 : 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap'],
    });

    if (environment !== 'production') {
      datadogRum.startSessionReplayRecording();
    }
  }

  return (
    <Auth0Provider
      domain={env.VITE_AUTH0_DOMAIN}
      clientId={env.VITE_AUTH0_CLIENT_ID}
      useRefreshTokens
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: env.VITE_AUTH0_REDIRECT_URI,
      }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkTheme}>
          <StyledEngineProvider injectFirst>
            <RouterProvider router={router} />
          </StyledEngineProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
};

export default App;
