import { useCallback } from 'react';
import axios from '@/apis/tass-api/axios';
import { ApiUserDto } from '@/apis/tass-api/types';

export const useDownloadCredFile = () => {
  const downloadFile = useCallback(async () => {
    try {

      const response = await axios.get<ApiUserDto>('/api/ApiUser/GenerateSDKToken');
      const tokenData = response.data;

      if (!tokenData || typeof tokenData !== 'string') {
        throw new Error('Invalid token response');
      }

      const blob = new Blob([tokenData], { type: 'text/plain' });

      // Create a download URL
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'tsdk.cred');
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading the .cred file:', error);
    }
  }, []);

  return downloadFile;
};
