import axios from './axios';
import { UserDto } from './types';

export const getMyUser = async () => {
  const { data } = await axios.get<UserDto>('/api/User/Mine');
  return data;
};

export const sdkLogin = async () => {
  const user = await axios.get<UserDto>('/api/User/Mine');
  const userData = {
    userId: user.data.id,
    product: 'TSDK',
  };
  const response = await axios.post('/api/Subscription/GetUserProductAccess', userData);
  const data = response.data.productAccess;
  // Check if expiresAt is defined and is a string
  if (!data?.expiresAt || typeof data.expiresAt !== 'string' || new Date(data.expiresAt) < new Date()) {
    throw new Error('Invalid TSDK subscription');;
  }
};
