import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { CopyToken } from '@/components/CopyToken';
import { TrblDownloadIcon, TrblFileIcon } from '@/components/Icons';
import { SecondaryButton, TertiaryButton } from '@/components/Shared/Buttons';

import { CodeBlock } from '@/components/CodeBlock';
import { getEnvironment } from '@/utils';
import { useDownloadLatestSdk } from './hooks/useDownloadLatestSdk';
import { useDownloadCredFile } from './hooks/useDownloadCredFile';
import styles from './styles.module.scss';
import { getRefreshToken } from './utils';

interface SdkSsoInfoProps {
  successfulSdkLogin: boolean;
  successfulSdkLoginFallback: boolean;
}

export const SdkSsoInfo: React.FC<SdkSsoInfoProps> = ({ successfulSdkLogin, successfulSdkLoginFallback }) => {
  const [token, setToken] = useState<string | null>(null);
  const { getAccessTokenSilently, logout } = useAuth0();
  const downloadLatestSdk = useDownloadLatestSdk();
  const downloadTokenFile = useDownloadCredFile();

  useEffect(() => {
    const getToken = async () => {
      const bearerToken = await getAccessTokenSilently();
      const refreshToken = getRefreshToken();
      let runningEnvironment = getEnvironment();
      runningEnvironment = runningEnvironment === 'local' ? 'dev' : runningEnvironment;

      if (runningEnvironment && refreshToken && bearerToken) {
        const tokenString = `${bearerToken}|${refreshToken}|${runningEnvironment}`;
        setToken(btoa(tokenString));
      }
    };

    if (successfulSdkLogin) {
      getToken().catch((e) => {
        if (e.error === 'login_required' || e.error === 'invalid_grant') {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      });
    }
  }, [successfulSdkLogin, getAccessTokenSilently, logout]);

  if (successfulSdkLogin) {
    return (
      <div className={styles['sdk-info-page']}>
        <div className={styles['header']}>
          <h1>Treble SDK</h1>
          <div className={styles['header-actions']}>
            <SecondaryButton
              label="Documentation"
              width={'fit-content'}
              icon={<TrblFileIcon />}
              onClick={() => window.open('https://docs.treble.tech/treble-sdk', '_blank', 'noopener,noreferrer')}
            />
            <TertiaryButton
              onClick={downloadLatestSdk}
              icon={<TrblDownloadIcon fill={'#00f5ba'} />}
              label="Download latest version"
            />
          </div>
        </div>
        <SsoTokenSection token={token} />
      </div>
    );
  }

  if (successfulSdkLoginFallback) {
    return (
      <div className={styles['sdk-info-page']}>
        <div className={styles['header']}>
          <h1>Treble SDK</h1>
          <div className={styles['header-actions']}>
            <SecondaryButton
              label="Documentation"
              width={'fit-content'}
              icon={<TrblFileIcon />}
              onClick={() => window.open('https://docs.treble.tech/treble-sdk', '_blank', 'noopener,noreferrer')}
            />
            <TertiaryButton
              onClick={downloadTokenFile}
              icon={<TrblDownloadIcon fill={'#00f5ba'} />}
              label="Download SDK Credentials File"
            />
          </div>
        </div>
        <SdkTokenSection />
      </div>
    );
  }
};

const SsoTokenSection: React.FC<{ token: string | null }> = ({ token }) => (
  <div className={styles['info-section']}>
    <h2>SSO token</h2>
    <CopyToken token={token || ''} />
    <h2>Using the SSO token</h2>
    <p>
      There are two ways you can choose from when using the SSO token. <br /> You can set the SSO token as an
      environment variable or alternatively you can provide the SSO token when initializing the SDK.
    </p>
    <div className={styles['info-section']}>
      <h4>Option 1: Set the SSO token as an environment variable</h4>
      <CodeBlock showLineNumbers language="python">
        {`# Note: On windows the command is "set" instead of "export" \nexport TSDK_SSO_TOKEN=${token}`}
      </CodeBlock>
      <p>Then you can initialize the SDK this way:</p>
      <CodeBlock showLineNumbers language="python">
        {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
      </CodeBlock>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 2: Provide the SSO token when initializing the SDK</h4>
      <p>
        If you don't want to set the SSO token as an environment variable, you can pass it directly when initializing the SDK.
        This allows you to use the token in a programmatic manner. Here is an example:
      </p>
      <CodeBlock showLineNumbers language="python">
        {`from treble_tsdk.tsdk import TSDK, TSDKCredentials \ntsdk = TSDK(TSDKCredentials.sso('${token}'))`}
      </CodeBlock>
    </div>
  </div>
);

const SdkTokenSection: React.FC = () => (
  <>
    <div className={styles['info-section']}>
      <h2>SDK token</h2>
      <TertiaryButton
        onClick={useDownloadCredFile()}
        icon={<TrblDownloadIcon fill={'#00f5ba'} />}
        label="Download SDK Credentials File"
      />
    </div>
    <div className={styles['info-section']}>
      <h2>Using the SDK token</h2>
      <p>
        There are three ways you can choose from when using the SDK token. <br /> You can place the SDK token in a default location, set it as an
        environment variable or alternatively you can provide the SDK token when initializing the SDK.
      </p>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 1: Place the SDK token in a default location</h4>
      <p>When you create a TSDK object it will search for a .cred file in a few locations, depending on your operation system. Please put the .cred file in the correct directory:</p>
      <CodeBlock showLineNumbers language="python">
        {`Windows: %userprofile%/AppData/Local/treble/tsdk/tsdk.cred and %userprofile%/AppData/Roaming/treble/tsdk/tsdk.cred\nLinux: ~/.config/treble/tsdk.cred\nMacos: ~/.config/treble/tsdk.cred and ~/Library/Application Support/treble/tsdk.cred`}
      </CodeBlock>
      <p>Then you can initialize the SDK this way:</p>
      <CodeBlock showLineNumbers language="python">
        {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
      </CodeBlock>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 2: Provide the SDK token when initializing the SDK</h4>
      <p>
        Another option is to directly pass the SDK token when initializing the SDK. This is ideal for scenarios where the token is
        generated dynamically or needs to be provided programmatically. Here’s an example of how to pass it:
      </p>
      <CodeBlock showLineNumbers language="python">
        {`from treble_tsdk.tsdk import TSDK, TSDKCredentials \ntsdk = TSDK(TSDKCredentials.from_file("/path/to/cred/tsdk.cred"))`}
      </CodeBlock>
    </div>
    <div className={styles['info-section']}>
      <h4>Option 3: Set the SDK token as an environment variable</h4>
      <p>
        If you prefer to manage the SDK token through the environment, you can set it as an environment variable. This is commonly used in production
        or automated environments where hardcoding tokens isn't preferred.
      </p>
      <CodeBlock showLineNumbers language="python">
        {`# Note: On windows the command is "set" instead of "export" \nexport TSDK_CREDENTIALS=[YOUR_TOKEN_HERE]`}
      </CodeBlock>
      <p>Then you can initialize the SDK this way:</p>
      <CodeBlock showLineNumbers language="python">
        {'from treble_tsdk.tsdk import TSDK \ntsdk = TSDK()'}
      </CodeBlock>
    </div>
  </>
);
